
import { defineComponent } from 'vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import emojisMap from '@/assets/emojisMap.json'

export default defineComponent({
  components: { TmEmoji },
  setup() {
    const emojis = emojisMap

    return { emojis }
  },
})
