import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emojis, (item, i) => {
      return (_openBlock(), _createBlock(_component_tm_emoji, {
        key: i,
        name: item,
        class: "ma-2",
        size: "xxxLarge"
      }, null, 8, ["name"]))
    }), 128))
  ]))
}